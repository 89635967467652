@import '__importable.scss';
$bfNavHeight: 4.4rem;
$entranceAnimation: cubic-bezier(0.23, 0.77, 0.19, 0.97);

.container {
	width: 100%;
	background-color: $gray-0;
	height: 100%;
	display: grid;
	position: fixed;
	grid-template-columns: 1fr;
	grid-template-rows: min-content auto;
	grid-template-areas:
		'view'
		'interactive';
	overflow-y: hidden;
	transform: translateY(0);
	transition: transform 500ms $entranceAnimation;
	pointer-events: initial;
	z-index: $zindex-10;
	margin-top: -$headerHeight;
	max-width: $max-width;

	@media screen and (min-width: $media-lg) {
		opacity: 1;
		visibility: visible;
		transform: none;
		transition: opacity 250ms $entranceAnimation, visibility 250ms $entranceAnimation;
		gap: 1.6rem;
	}

	// iPad + Phone landscape stylings
	@media screen and (min-width: $media-md-lg),
	only screen and (min-width: 66.7em) and (max-height: 37.5em) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2),
	only screen and (min-width: 73em) and (max-width: 74em) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 3) {
		grid-template-rows: 1fr !important;
		grid-template-columns: 2fr 1fr !important;

		&[data-step='4'],
		&[data-step='5'] {
			grid-template-columns: 1fr 1fr !important;
		}

		&[data-new-layout='true'] {
			grid-template-columns: 2fr 1fr !important;
		}

		grid-template-areas: 'view interactive' !important;
	}

	@media only screen and (min-device-width: 102.4em) and (max-device-width: 136.6em) and (-webkit-min-device-pixel-ratio: 2) {
		grid-template-columns: 1fr;
		grid-template-rows: min-content auto;
		grid-template-areas:
			'view'
			'interactive';
	}

	@media screen and (width >=$media-lg) {
		padding: 0 1.6rem;
	}

	@media screen and (width >=$media-xl) {
		padding: 0 3.2rem;
	}

	@media screen and (width >=$media-xxl) {
		padding: 0 6.4rem;
	}

	@media screen and (width >=$max-width) {
		padding: 0 12.8rem;
	}
}

.bf-all-tops-container {
	@media screen and (min-width: $media-md-lg) {
		display: flex;
		flex-direction: column;
	}
}

.view-pane {
	grid-area: view;
	overflow-y: hidden;
	padding: 0;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	@media screen and (min-width: $media-md-lg) {
		padding-left: 0.8rem;
		padding-bottom: 3.2rem;
	}

	.product-view-animation {
		height: max-content;
	}
}

.all-tops-view-pane {
	@media screen and (min-width: $media-sm-max ) {
		padding: 1.6rem;
	}

	@media screen and (min-width: $media-md-lg) {
		padding: 1.6rem 0 0;
	}
}

.progress-nav {
	height: 100%;
	max-height: $bfNavHeight;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 1rem;
	border-bottom: 1px solid $gray-2;
}

.interactive-pane {
	@include noScrollBar;
	grid-area: interactive;
	z-index: $zindex-3;
	width: 100%;
	transition: height 500ms ease-in-out;
	will-change: height;
	overflow-y: scroll;
	overscroll-behavior-y: none;
	height: 100%;

	@media screen and (min-width: $media-lg) {
		height: inherit;
	}

	display: flex;
	flex-flow: column nowrap;

	>div:first-child {
		flex: 3;
	}

	>div:last-child {
		flex: 1;
		flex-grow: 0;
	}

	&[data-build-flow-step="TOP_FRAMES"].filtering-active {
		.current-step {
			overflow-y: unset; //Prevents double scrolling issue caused by InstantSearch
		}
	}
}

.current-step {
	@include noScrollBar;
	position: relative;
	grid-area: current;
	overflow-y: scroll;
	overflow-x: hidden;
	display: flex;
	flex-direction: column;
	height: calc(100% + 0.4rem);
	z-index: 1;

	@supports (-webkit-overflow-scrolling: touch) {
		-webkit-overflow-scrolling: touch;
	}
}

.bf-all-tops-cart-actions {
	z-index: $zindex-1;
	background: $gray-0;
	position: fixed;
	bottom: 0;
	width: 100%;

	@media screen and (width >=$media-lg) {
		width: 33%;
		right: 1.6rem;
	}

	@media screen and (width >=$media-xl) {
		right: 3.2rem;
	}

	@media screen and (width >=$media-xxl) {
		right: 6.4rem;
	}

	@media screen and (width >=$max-width) {
		right: 12.8rem;
	}
}

.hide-cart-action{
	display: none;
}

.stepContainer {
	@media screen and (min-width: $media-sm-max ) {
		position: absolute;
		left: 33%;
		top: 1rem;
	}
}
