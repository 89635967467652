@import '__importable.scss';
.container {
    padding-block: 1.6rem;
    display: flex;
    flex-flow: column-reverse;
}

.image-container {
    margin-bottom: 0.8rem;
    margin-left: -1.6rem;
    height: 275px;
    width: calc(100% + 3.2rem);
    position: relative;
}

.image {
    width: 100%;
    height: auto;
    object-fit: cover;
}

@media screen and (min-width: $media-md) {
    .container {
        padding-block: 3.2rem;
        flex-flow: row;
        max-width: 120em;
        margin: 0 auto;
    }

    .image-container {
        width: 100%;
        margin: 0;
        flex: 1;
        border-radius: $radius-3;
        overflow: hidden;
    }

    .text-container {
        padding-right: 3.2rem;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: flex-start;
        flex: 2;
    }
}

@media screen and (max-width: $media-md-max) {
    .container {
        flex-flow: column;
        margin: 0 auto;
    }

    .image-container {
        width: 110%;
        margin: 0 -1.6rem;
        overflow: hidden;
        align-self: center;
        
    }

    .text-container {
        margin-bottom: 1.6rem;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: flex-start;
        flex: 2;


        p {
            max-width: 100% !important;

        }
        
    }
}