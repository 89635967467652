@import '__importable.scss';
.vto-popup {
	padding: 0;
	overflow: hidden;
	width: 100%;
	max-width: 100vw;
	overflow-y: auto;

	@media screen and (max-width: $media-xsm) and (min-height: $media-sm) {
		max-height:  calc(100vh - 12rem);
	}

	&__fitmix-container {
		height: 37.5rem;

		iframe {
			border-radius: $radius-2 $radius-2 0 0;
		}
	}

	&__pair-logo {
		svg {
			position: absolute;
			top: 1.2rem;
			left: 50%;
			transform: translateX(-50%);
			height: 3.2rem;
			width: 6.4rem;
			border-radius: 0 !important;

			path {
				fill: $white;
			}
		}
	}

	&__close {
		position: absolute;
		top: 1rem;
		right: 1rem;
		padding: 0.9rem 1.6rem 1rem 1.6rem;
		line-height: 1;

		&.hiding {
			display: none;
			pointer-events: none;
		}
	}

	&__file-reader {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		opacity: 0;
		width: 100%;
		height: 100%;
		cursor: pointer;
		font-size: 0;
		z-index: 2;
	}

	&__options-container {
		position: relative;
		padding: 0 1.6rem 1.6rem;

		.swatch-control {
			gap: 0.5rem;
		}
	}
}

.vto-icon__container {
	position: absolute;
	top: -8rem;
	left: 50%;
	transform: translateX(-50%);
	padding: 0 0.5rem;

	display: flex;
	justify-content: space-between;

	.vto-icon {
		padding: 0;
	}
	@media screen and (min-width: $media-sm) {
		left: 0;
		transform: unset;
		padding: 0;
		padding-left: 1rem;
	}
}

.vto-icon {
	height: auto;
	width: auto;
	border: 0;
	position: relative;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-flow: column;
	background-color: transparent;
	color: $white;
	cursor: pointer;

	&__text {
		color: inherit;
		font-style: normal;
		font-weight: 500;
		font-size: 1.4rem;
		line-height: 2.1rem;
		width: max-content;
	}

	&.hiding {
		display: none !important;
	}

	svg:not(.logo) {
		border-radius: $radius-7;
		border: 2px solid transparent;
	}

	&:hover {
		svg:not(.logo) {
			background: rgba(0, 0, 0, 0.32);
			transition: $transition-1;
		}
	}

	&:active {
		svg:not(.logo) {
			background: rgba(0, 0, 0, 0.64);
			transition: $transition-1;
		}
	}

	&:focus {
		outline: 0;
	}

	svg:not(.logo):focus-within {
		background: rgba(0, 0, 0, 0.32);
		border-color: $blue;
		transition: $transition-1;
	}
}

.select-frame {
	&__carousel {
		display: flex;
		padding: 0.8rem;
		overflow-x: scroll;
		scroll-snap-type: x mandatory;
		justify-content: space-between;
		gap: 0.3rem;

		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */

		&::-webkit-scrollbar {
			display: none;
		}
	}

	&__cell {
		min-width: 15rem;
		height: 10.8rem;
		border: 2px solid transparent !important;
		border-radius: $radius-2;
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		scroll-snap-align: center;
		cursor: pointer;

		img {
			mix-blend-mode: multiply;
			width: 13rem;
			height: 6.5rem;
		}

		.title {
			font-size: 1.4rem;
			line-height: 1.7rem;
		}

		&:hover {
			border-color: $gray-2;
			background-color: $gray-2;
		}

		&.active {
			border-color: $blue !important;
		}
	}
}

.swatch__heading {
	gap: 0.5rem;
	width: fit-content;
	margin: auto;

	.frame-color-name,
	.label {
		font-size: 1.4rem;
		line-height: 1.7rem;
	}
}

.adjust-pd {
	position: absolute;
	color: $white;
	padding: 1rem;
	top: 0;
	transition: $transition-1;

	&__blur {
		position: absolute;
		background-color: rgba(0, 0, 0, 0.8);
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		z-index: 1;
	}

	&__container {
		position: relative;
		max-width: 31.5rem;
		margin: 0 auto;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-flow: column;
		z-index: 2;
	}

	&__divider {
		position: relative;
		width: 100%;

		&::before,
		&::after {
			content: '';
			height: 1px;
			background: $white;
			width: 45%;
			position: absolute;
			top: 50%;
		}

		&::before {
			left: 0;
		}

		&::after {
			right: 0;
		}
	}

	&.hiding {
		opacity: 0;
		pointer-events: none;
	}

	.vto-popup__close {
		z-index: 2;
	}

	.line {
		height: 1px;
		background: $white;
		width: 100%;
	}

	@media screen and (min-width: $media-sm) {
		&__container {
			max-width: 38.5rem;
		}
	}
}

.relative {
	position: relative;
}
