@import '__importable.scss';
.container {
	display: flex;
	justify-content: center;
	align-items: baseline;
	gap: 1rem;
	width: 100%;
	height: auto;
	min-height: 6.4rem;
	max-height: 6.4rem;
	padding: 1rem;
	font-size: 1.4rem;
	text-align: center;
	background-color: $gray-0;
	z-index: 2;

	@media screen and (width < $media-md) {
		height: 5.6rem;
	}

	@media screen and (width < $media-xxsm) {
		height: 4.8rem;
	}
}

.list {
	display: flex;
	justify-content: space-between;
	align-items: center;

	svg {
		margin: 0 0.5rem;
	}
}

.step {
	display: flex;
	justify-content: center;
	position: relative;
	width: 8rem;

	@media (hover: hover) {
		&:hover {
			.copy.complete:not(.current) {
				background-color: rgba(0, 0, 0, 0.04);
				color: $blue-1;
				transition: all $transition-4 ease;
				cursor: pointer;
			}
		}
	}

	.line {
		margin-top: 0.9rem;
		position: absolute;
		left: 5.8rem;
		height: 0.2rem;
		border-radius: 1px;
		width: 5.3rem;
	}
}

.circle {
	width: 2rem;
	height: 2rem;
	background: $gray-3;
	color: $gray-0;
	border-radius: $radius-round;
	text-align: center;
	cursor: default;
	align-content: center;

	span {
		color: $gray-0;
		font-weight: 500;
		font-size: 1.4rem;

		&.current,
		&.complete {
			color: $gray-0;
		}
	}

	&.complete {
		background: $blue-1;
		transition: background $transition-4 ease;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 2px solid $blue-2;
	}

	&.skip {
		background: $gray-3;
		color: $gray-0;
		border: 2px solid $gray-3;
	}

	&.current {
		background: $white;
		border: 2px solid $blue-1;
		transition: background $transition-4 ease;
		display: flex;
		align-items: center;
		justify-content: center;

		span {
			color: $blue-1;
		}
	}
}

.copy {
	display: flex;
	align-items: center;
	color: $gray-4;
	line-height: 1.8rem;
	font-weight: 500;
	font-size: 1.2rem;
	padding: 0 0.25rem;
	border-radius: 0.25rem;
	transition: 300ms color ease;
	cursor: default;
	transition: all $transition-4 ease;

	&.complete {
		color: $blue-2;
		transition: color $transition-4 ease;
	}

	&.current {
		color: $gray-5;
		transition: color $transition-4 ease;
	}

	&.skip {
		color: $gray-4;
		transition: color $transition-4 ease;
	}
}
